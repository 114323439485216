<template>
  <div class="buyerFront">
    <!-- 回顶部 -->
    <el-backtop :visibility-height="100"
                :bottom="10"
                :right="100">
      <i class="el-icon-top"
         style="font-size: 20px"></i>
    </el-backtop>
    <!-- 头部 -->
    <header>
      <!-- logo -->
      <div class="logo"
           @click="$router.push('/F_home')">
        <img src="../../assets/img/credis-logo.png"
             alt="" />
      </div>
      <!-- 导航栏 -->
      <el-menu :default-active="activeIndex"
               class="el-menu-demo"
               mode="horizontal"
               @select="handleSelect"
               background-color="white"
               text-color="#444"
               router
               active-text-color="#ff9933">
        <!-- deals 折扣模块 -->
        <el-menu-item index="/F_deals">Deals</el-menu-item>
        <!-- freevbies 无积分赠品模块 -->
        <el-menu-item index="/F_freebies">Freebies</el-menu-item>
        <!-- earn phoint 有积分赠品模块 -->
        <el-menu-item index="/F_earnPoints">Earn Points</el-menu-item>
        <!-- faq 流程介绍模块 -->
        <el-submenu index="/F_buyerFAQ">
          <template slot="title">FAQ</template>
          <el-menu-item index="/F_buyerFAQ">Buyer FAQ</el-menu-item>
          <el-menu-item index="/F_sellerFAQ">Seller FAQ</el-menu-item>
        </el-submenu>
        <!-- 搜索框 -->
        <el-input placeholder="SEARCH PRODUCTS"
                  v-model="inputText"
                  class="input-with-select">
          <el-select class="test"
                     v-model="select"
                     slot="prepend">
            <el-option :label="seachItem.name"
                       :value="seachItem.value"
                       v-for="seachItem in seachList"
                       :key="seachItem.value">
            </el-option>
          </el-select>
          <el-button slot="append"
                     icon="el-icon-search"
                     @click="seachGood()"
                    @keydown="keySub($event)"></el-button>
        </el-input>
      </el-menu>
      <!-- 国家选择 -->
      <el-dropdown trigger="click"
                   @command="handleCommand">
        <span class="el-dropdown-link">
          <!-- 国旗 -->
          <span :class="$store.state.countryList"></span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <!-- 国旗下拉列表 -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(countryItem, countryindex) in countryList"
                            :key="countryindex"
                            @click="countryTest()"
                            :command="countryItem.className">
            <span :class="countryItem.className"></span>
            <span class="countryText"
                  style="margin-left: 10px">{{ countryItem.name }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 登录/注册 -->
      <div class="login">
        <i class="el-icon-user"></i>
        <!-- 未登录显示 -->
        <div v-if="!$store.state.loginSucc" style="font-size:1.2rem">
          <!-- 登录 -->
          <span @click="goLogin()">Log in</span>
          /
          <!-- 注册 -->
          <span @click="$router.push('/F_register')">Sigh Up</span>
        </div>
        <!-- 登录成功显示 -->
        <div v-if="$store.state.loginSucc" style="display:flex;">
          <!-- 姓 -->
          <span style="display:block;float:left;margin-right: 15px;text-transform:capitalize;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width:80px;height: 30px;"
                @click="$router.push('/A_dashBoard')" ref="dom">{{$store.state.username}}</span>
          <!-- 退出登录 -->
          <span style="padding: 0 10px"
                @click="logoutClick()">Logout</span>
        </div>
      </div>
      <!-- 卖方中心 -->
      <!-- <el-button @click="$router.push('/sellerCenter')"
                 class="sellerBtn">Seller Center</el-button> -->
    </header>
    <!-- 内容 -->
    <main class="main">
      <router-view v-if="isRouteAlive"></router-view>
    </main>
    <!-- 底部 -->
    <footer class="footbar">
      <!-- 积分查看 -->
      <div class="footTop">
        <div class="footTop-left">
          <svg t="1602902731613"
               class="icon"
               viewBox="0 0 1024 1024"
               version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="3451"
               width="150"
               height="150">
            <path d="M552.96 614.4v122.88H143.36v-122.88h409.6z m227.7376-121.856c32.44032 32.44032 38.58432 81.18272 18.432 119.76704l61.2352 61.27616-43.4176 43.4176-61.27616-61.2352a102.44096 102.44096 0 1 1 25.06752-163.2256zM266.24 655.36H225.28v40.96h40.96v-40.96z m81.92 0H307.2v40.96h40.96v-40.96z m331.20256-119.35744A40.96 40.96 0 1 0 737.28 593.92a40.96 40.96 0 0 0-57.91744-57.91744zM552.96 450.56v122.88H143.36v-122.88h409.6z m-286.72 40.96H225.28v40.96h40.96v-40.96z m81.92 0H307.2v40.96h40.96v-40.96z m532.48-204.8v122.88H143.36V286.72h737.28zM266.24 327.68H225.28v40.96h40.96V327.68z m81.92 0H307.2v40.96h40.96V327.68z"
                  fill="#ffffff"
                  p-id="3452"></path>
          </svg>
        </div>
        <div class="footTop-center">
          <div class="fc-text">
            Click into your points background to view your points details
          </div>
          <div class="fc-integral">
            <div class="fc-buyer">
              <a href=" https://crediscounts.com/#/F_login" style="color: white">Buyer</a>
            </div>
            <div class="fc-seller">
              <a href=" https://crediscounts.com/seller/#/F_login" style="color: white">Seller</a>
            </div>
          </div>
        </div>
        <div class="footTop-right">
          <p>Newsletter</p>
          <p>Be the first to get the best free products:</p>
          <div class="fr-input">
            <el-input placeholder="Your Email Address…"
                      v-model="buttonInput">
              <template slot="append">Subscribe</template>
            </el-input>
          </div>
        </div>
      </div>
      <!-- 关于 -->
      <div class="footText">
        <div class="footText-top">
          <div class="popoverBox">
            <el-popover placement="bottom"
                        trigger="hover"
                        :open-delay="openNum">
                <p style="cursor: pointer;"  @click="$router.push('/F_about')">About Crediscounts</p>
                <p style="cursor: pointer;" @click="$router.push('/F_buyerFAQ')">Buyer FAQ</p>
                <p style="cursor: pointer;" @click="$router.push('/F_sellerFAQ')">Sell FAQ</p>
              <el-button slot="reference">About</el-button>
            </el-popover>
            <strong>|</strong>
          </div>
          <div class="popoverBox"
               v-for="(item, index) in gridData"
               :key="index">
            <el-popover placement="bottom"
                        trigger="hover"
                        :open-delay="openNum">
              <p v-for="(pItem, pindex) in item.pName"
                 :key="pindex">
                <a href="#">{{ pItem.textP }}</a>
              </p>
              <el-button slot="reference">{{ item.name }}</el-button>
            </el-popover>
            <strong v-if="item.bool">|</strong>
          </div>
        </div>
        <ul class="footText-center">
          <li>
            <a href="https://www.facebook.com/" target="_blank">
              <img src="../../assets/img/f-share-facebook.png"
                   alt />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/" target="_blank">
              <img src="../../assets/img/f-share-instagram.png"
                   alt />
            </a>
          </li>
          <li>
            <a href="http://www.tuiteblog.com/" target="_blank">
              <img src="../../assets/img/f-share-twitter.png"
                   alt />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/" target="_blank">
              <img src="../../assets/img/f-share-youtube.png"
                   alt />
            </a>
          </li>
        </ul>
        <div class="footText-bottom">
          ©Copyright 2020 crediscounts
            <el-link :underline="false" type="primary" @click="$router.push('/F_terms')" style="color:#409EFF;font-size: 16px;vertical-align: top;"> Terms </el-link>
            |
            <el-link :underline="false" type="primary" @click="$router.push('/F_privacy')" style="color:#409EFF;font-size: 16px;vertical-align: top;"> Privacy </el-link>
            | All Rights Reserved.
        </div>
        <div class="gongan">
          <img src="../../assets/img/gongan.png" alt="">
          <a href="https://beian.miit.gov.cn/" target="_blank">
            粤ICP备2020126125号
          </a>
        </div>
      </div>
    </footer>
    <!-- 右边圆圈 -->
    <div class="rightImg">
      <!-- <img @click="$router.push('/F_point')"
           src="../../assets/img/point1.png"
           alt=""> -->
      <!-- <img @click="$router.push('/F_work')"
           src="../../assets/img/works.png"
           alt=""> -->
    </div>
  </div>
</template>

<script>
// 引入css文件
// import '../../assets/less/buyerFront.css'
// 引入token
import {
  getToken, // 获取token
  removeToken // 删除token
} from '../../utils/token'
// 引入vuex
// import { mapState } from 'vuex'
export default {
  provide () {
    return {
      reload: this.relord
    }
  },
  data () {
    return {
      // 局部刷新
      isRouteAlive: true,
      // 当前激活路由
      activeIndex: '',
      // 搜索框内容
      inputText: '',
      // 选择的种类
      select: '1',
      // 搜索种类
      seachList: [
        { name: 'ALL', value: '1' },
        { name: 'DEALS', value: '2' },
        { name: 'FREEBIES', value: '3' },
        { name: 'EARN POINTS', value: '4' }
      ],
      // 国家列表
      countryList: [
        // 美国
        { name: 'US', className: 'flag-icon flag-icon-us', img: require('../../assets/img/flag.png') },
        // 加拿大
        { name: 'CA', className: 'flag-icon flag-icon-ca', img: require('../../assets/img/flag.png') },
        // 墨西哥
        { name: 'MX', className: 'flag-icon flag-icon-mx', img: require('../../assets/img/flag.png') },
        // 英国
        { name: 'UK', className: 'flag-icon flag-icon-uk', img: require('../../assets/img/flag.png') },
        // 德国
        { name: 'DE', className: 'flag-icon flag-icon-de', img: require('../../assets/img/flag.png') },
        // 法国
        { name: 'FR', className: 'flag-icon flag-icon-fr', img: require('../../assets/img/flag.png') },
        // 意大利
        { name: 'IT', className: 'flag-icon flag-icon-it', img: require('../../assets/img/flag.png') },
        // 西班牙
        { name: 'ES', className: 'flag-icon flag-icon-es', img: require('../../assets/img/flag.png') },
        // 日本
        { name: 'JA', className: 'flag-icon flag-icon-jp', img: require('../../assets/img/flag.png') },
        // 荷兰
        { name: 'NL', className: 'flag-icon flag-icon-nl', img: require('../../assets/img/flag.png') }
      ],
      // 登录成功
      // loginSucc: false,
      // 用户名
      // username: '',
      // 底部列表
      gridData: [
        {
          bool: true,
          name: 'Contact Us ',
          pName: [
            {
              textP: 'Buyer :'
            },
            {
              textP: 'service@crediscounts.com'
            },
            {
              textP: 'Seller :'
            },
            {
              textP: 'support@crediscounts.com'
            }
          ]
        },
        {
          bool: true,
          name: 'seller',
          pName: [
            {
              textP: 'Seller Center'
            },
            {
              textP: 'Advertising Opportunities'
            }
          ]
        },
        {
          bool: false,
          name: 'We Accept',
          pName: [
            {
              textP: 'Alipay'
            },
            {
              textP: 'Visa'
            },
            {
              textP: 'Paypal'
            }
          ]
        }
      ],
      // 邮件订阅
      buttonInput: '',
      openNum: 200,
      timer: ''
    }
  },
  // 监测vue实例数据变化
  watch: {
    // 监测路由实例
    $route: {
      handler: function (val, oldval) {
        this.activeIndex = val.path
        // // ('val ==>', val)
      },
      deep: true
    },
    '$store.state.username': {
      handler: function (val) {
        this.$store.commit('getUser')
      },
      deep: true
    }
  },
  created () {
    // 判断城市
    if (!localStorage.getItem('buyer-countyr')) {
      localStorage.setItem('buyer-countyr', 'flag-icon flag-icon-us')
      this.$store.commit('test')
    }
    // 获取token
    const token = getToken()
    // 判断是否含有token
    if (token) {
      this.$store.commit('getLoginbool', true)
    } else {
      this.$store.commit('getLoginbool', false)
    }
    // if (token) {
    //   ('1111---------')
    //   // this.loginSucc = true
    //   this.$store.commit('getLoginbool', true)
    //   // 调用接口
    //   this.$store.commit('getUser')
    //   const tokenTime = parseInt(localStorage.getItem('tokenTime'))
    //   const newTime = Date.parse(new Date())
    //   (newTime, tokenTime)
    //   if (newTime > tokenTime) {
    //     removeToken()
    //     this.$message.error('Please login again')
    //     this.$router.push('/F_login')
    //   }
    // } else {
    //   ('22222---------')
    //   if (!localStorage.getItem('countyr')) {
    //     localStorage.setItem('countyr', 'flag-icon flag-icon-um')
    //   }
    //   this.$store.commit('test')
    //   this.$store.commit('getLoginbool', false)
    // }
  },
  mounted () {
    // (this.$store.state)
    this.$nextTick(() => {
      // (this.$refs.dom.innerHTML)
    })
    this.windowRouter()
  },
  methods: {
    // 局部刷新
    reload () {
      this.isRouteAlive = false
      this.$nextTick(() => {
        this.isRouteAlive = true
      })
    },
    seachGood () {
      // 全部上哦搜索
      if (this.select === '1') {
        this.$router.push({ path: 'F_seachDetails', query: { goodUrl: '/product/search/all', inputText: this.inputText } })
        // this.$router.go(0)
        this.reload()
        // 折扣搜索
      } else if (this.select === '2') {
        this.$router.push({ path: 'F_seachDetails', query: { goodUrl: '/product/search/deal', inputText: this.inputText } })
        // this.$router.go(0)
        this.reload()
        // 无积分赠品搜索
      } else if (this.select === '3') {
        this.$router.push({ path: 'F_seachDetails', query: { goodUrl: '/freebies/product/noPoint', inputText: this.inputText } })
        // this.$router.go(0)
        this.reload()
        // 积分赠品搜索
      } else if (this.select === '4') {
        this.$router.push({ path: 'F_seachDetails', query: { goodUrl: '/freebies/product/point', inputText: this.inputText } })
        // this.$router.go(0)
        this.reload()
      }
    },
    keySub (e) {
      if (e.keyCode === 13) {
        this.seachGood()
      }
    },
    handleSelect (key, keyPath) {
      // (key, keyPath)
    },
    // 页面刷新显示当前路由
    windowRouter () {
      // // (this.$route.path)
      var rou = this.$route.path
      this.activeIndex = `${rou}`
    },
    // 退出登录
    logoutClick () {
      // 清楚token
      removeToken()
      this.$router.push('/F_home')
      localStorage.removeItem('buyer-tokenTime')
      this.$message('Log out') // 弹框提示
      // this.$router.go(0)
      // this.loginSucc = false
      this.$store.commit('getLoginbool', false)
    },
    // 国家选择下拉框
    handleCommand (command) {
      localStorage.removeItem('buyer-countyr')
      localStorage.setItem('buyer-countyr', command) // 创建localStorage存储
      this.$store.commit('test')
      if (this.$route.path === '/F_home') {
        this.$router.go(0)
      } else {
        this.$router.push('/F_home')
        this.$router.go(0)
      }
      // this.$router.go(0)
    },
    goLogin () {
      this.$router.push({ path: '/F_login' })
    }
  }
}
</script>
<style lang="less" scoped>
.buyerFront {
    height: 100%;
    width: 100%;
    // 头部
    header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        height: 80px;
        width: 100%;
        padding: 10px 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        box-shadow: 0px 5px 10px #ccc;
        overflow: hidden;
        // @media screen and (min-width: 1610px) {
        //   width: 1980px;
        // }
        // @media screen and (max-width: 1600px) {
        //   width: 1600px;
        // }
        // logo
        .logo {
            width: 12%;
            cursor: pointer;
            overflow: hidden;

            img {
                margin-top: 2px;
                float: left;
                display: block;
                // max-height: 80px;
                width: 160px;
            }
        }

        // 导航栏
          .el-menu {
            width: 73%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 0;
            background-color: white;
            color: white;

            &:focus {
                outline: none;
            }
        }

         .el-menu-item {
            &:hover {
                background-color: transparent !important;
                color: #FF9933 !important;
            }
        }

         .el-submenu__title {
            color: black;

            &:hover {
                background-color: transparent !important;
                color: #FF9933 !important;
            }
        }

         .el-menu-item:not(.is-disabled) {
            color: white;
            font-weight: bolder;
            padding: 0 10px;

            &:hover {
                background-color: white;
                color: #FF9933;
            }
        }

         .el-input-group__prepend {
            border: 0;
        }

         .el-input--suffix {
            min-width: 145px !important;
            display: inline-block;
        }

         .el-input-group {
            // max-width: 600px;
            width: 70%;
            margin-left: 2%;

            &:focus {
                outline: 0;
            }
        }

        .test {

             .el-input {
                margin: 0;
            }

            &:hover  .el-input__inner {
                border-top: 1px solid #C7C7C7;
                border-bottom: 1px solid #C7C7C7;
                border-left: 1px solid #C7C7C7;
            }

             .el-input__inner {
                border-top: 1px solid #C7C7C7;
                border-bottom: 1px solid #C7C7C7;
                border-left: 1px solid #C7C7C7;
                border-right: 0;
                border-color: #C7C7C7 !important;
            }
        }

         .el-submenu__title {
            color: white;
            font-weight: bolder;
        }

         .el-submenu__title {
            i {
                color: black;
                font-weight: bolder;
            }
        }

         .el-input--suffix {
            background-color: #FFFFFF;
        }

         .el-input__inner {
            // border-top: 0;
            // border-bottom: 0;
            // border-left: 1px solid #C7C7C7;
            border: 1px solid #C7C7C7;
        }

         .el-input-group__append {
            background-color: #FF9933;
            border: 0;
            color: white;
            font-weight: bolder;
            font-size: 20px;
        }

        // 国家选择
         .el-dropdown-menu {
            left: 1100px !important;
        }

         .el-dropdown {
            margin-left: 20px;
            width: 3%;
        }

         .el-dropdown-link {
            display: flex;
            align-items: center;
            color: black;
        }

        .countryText {
            margin-left: 5px !important;
        }

        // login
        .login {
            margin-left: 15px;
            min-width: 180px;
            color: #808080;
            font-size: 20px;
            display: flex;
            justify-content: flex-start;

            span {
                cursor: pointer;
            }

            i {
                margin-right: 10px;
            }
        }

        .sellerBtn {
            color: #FF9933;
            background-color: #FDEDDE;
            border-color: #FF9933;
        }
    }

    // 内容
    main {
        // position: relative ;
        margin-top: 80px;
        background-color: #F2F2F2;
        // height: 100%;
    }

    // 底部
    .footbar {
        // position: relative;
        // bottom: 0;
        color: white;

        .footTop {
            background: #FF9933;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 40px;

            .footTop-left {
                flex: 1;

                svg {
                    width: 150px;
                }
            }

            .footTop-center {
                color: white;
                flex: 3;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                .fc-text {
                    font-size: 22px;
                    margin-bottom: 20px;
                }

                .fc-integral {
                    font-size: 20px;
                    font-weight: bolder;

                    .fc-buyer,
                    .fc-seller {
                        cursor: pointer;
                        display: inline-block;
                        // color: #212529;
                        // background: white;
                        padding: 10px 30px;
                        border-radius: 5px;
                        margin-right: 20px;
                        border: 2px solid white;
                    }

                    // .fc-seller {
                    //   display: inline-block;
                    //   color: #212529;
                    //   background: white;
                    //   padding: 10px 30px;
                    //   border-radius: 5px;
                    // }
                }
            }

            .footTop-right {
                flex: 2;
                font-size: 16px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                p {
                    text-align: left;
                    width: 100%;
                    margin-bottom: 15px;
                }

                .fr-input {
                    // display: flex;
                    // justify-content: center;
                    width: 70%;

                     .el-input-group {
                        border-radius: 20px;
                        overflow: hidden;
                        background: white;
                    }

                     .el-input-group__append {
                        background-color: #212529;
                        color: white;
                        border-radius: 0 20px 20px 0;
                    }
                }
            }
        }

        .footText {
            padding: 20px 40px 40px 40px;

            .footText-top {
                display: flex;
                justify-content: center;

                .popoverBox {
                     .el-button {
                        background: transparent;
                        border: 0;
                        color: black;
                        font-weight: 700;
                        font-size: 16px;
                    }
                }

                strong {
                    color: black;
                }
            }

            .footText-center {
                display: flex;
                justify-content: center;
                margin: 30px 0;

                img {
                    width: 48px;
                    height: 48px;
                    margin-left: 10px;
                }
            }

            .footText-bottom {
                text-align: center;
                font-size: 16px;
                color: #656565;
                padding-top: 30px;
                border-top: 1px solid #ccc;

                a {
                    color: black;
                }
            }
            .gongan {
              margin-top: 10px;
              color: black;
            }

        }
    }

    // 右边圈圈
    .rightImg {
        position: fixed;
        bottom: 0;
        right: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        z-index: 999;

        img {
            cursor: pointer;
            margin: 10px;
            display: block;
            float: left;
            // width: 100%;
        }
    }
}
</style>
<style scoped>
.buyerFront >>> .el-select  {
  min-width: 145px;
}
.header >>> .el-submenu__title {
  color: black;
}
.header >>> .el-submenu__title:hover {
  background-color: transparent !important;
  color: #FF9933 !important;
}
.main >>> .el-aside::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
#app >>> .el-popover>p {
  cursor: pointer;
}
</style>
